<template>
    <div class="credentials">
        <template v-if="proofMsg != null">
            <div class="credentialsButton">
                <el-button type="primary" @click="downImage">下 载</el-button>
            </div>
            <div class="credentialsImg" ref="imageTofile">
                <proofPaid :proof-data="proofMsg"></proofPaid>
            </div>
        </template>
    </div>
</template>

<script>

import proofPaid from '../components/proofPaid.vue';

import {utilsDownImg} from '../utils/utils.js';

export default {
    name: "proof",
    components: {
        proofPaid
    },
    data() {
        return {
            proofMsg: null,
        }
    },
    created() {
        this.proofMsg = JSON.parse(decodeURIComponent(this.$route.query.proofMsg));
    },
    methods: {
        downImage() {
            utilsDownImg(this.$refs.imageTofile, this.proofMsg.fileName)
        },
    }
}
</script>

<style scoped>

.credentials {
    width: 100%;
    height: 100%;
}

.credentialsButton {
    width: 1440px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 20px 0;
    text-align: right;
}

.credentialsImg {
    width: 1440px;
    margin: 0 auto;
}

</style>
